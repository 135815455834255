/* GlassHeader.css */

.glassToolbar {
  position: fixed;
  /* bottom: 2rem; */
  z-index: 700;
  width: 35%;
  padding: 0.5rem 2rem;
  /* height: 3rem; */
  /* max-height: 3rem; */
  /* margin: 2rem auto; */
  /* background: rgba(0, 0, 0, 0.4); */
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  gap: 2rem;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3rem;
}

.emojiList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.6rem;
}

.toolbarTitle {
  font-size: 1.4rem;
  font-weight: 600;
}

@media (max-width: 1240px) {
  .glassToolbar {
    width: 45%;
  }
}
@media (max-width: 980px) {
  .glassToolbar {
    width: 55%;
  }
}
@media (max-width: 768px) {
  .glassToolbar {
    flex-direction: column;
    gap: 1rem;
    bottom: 1rem;
    width: 50%;
  }
  .toolbarTitle {
    font-size: 1.2rem;
  }
}
@media (max-width: 400px) {
  .glassToolbar {
    flex-direction: column;
    gap: 1rem;
    bottom: 1rem;
    width: 70%;
  }
  .toolbarTitle {
    font-size: 1.2rem;
  }
}
