/* .emoji-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.emoji-button:hover {
  background-color: #0056b3;
}
 */

.emoji-border.active {
  border-bottom: "10px solid #fff";
}

.emoji-border {
  border-bottom: "none";
}
