.App {
  text-align: center;
  /* background-color: rgba(45, 45, 45, 0.1); */
  background-color: #2c2d2d;
  /* min-height: 100vh; */
  margin: 0;
  color: #fff;
}

.swal2-popup {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 3px;
  color: #fff;
}

.swal2-icon {
  font-size: 0.6rem; /* Resize the icon */
}

/* Container for the names */

.names-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
  gap: 0px;
  justify-content: flex-start; /* Center the entire grid */
  /* padding-left: 2rem; */
}

/* Individual name styling */
.names-grid div {
  font-size: 16px; /* Font size for the names */
  padding: 5px; /* Padding around each name */
  text-align: left;
}
.padding-bottom-mid {
  padding-bottom: 20px;
}
.swal2-content {
  padding-bottom: 40px; /* Add space below the content */
}

/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
