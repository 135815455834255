.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  /* min-height: 100vh;
  height: auto; */
  /* min-height: 100vh; */
  /* margin-top: 0px; */
  padding-top: 4rem;
  padding-bottom: 4rem;
  min-height: 100vh;
}

.content {
  /* height: auto; */
  /* height: min-content; */
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 2rem 4rem;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.3);
  font-size: 1.2rem;
  /* word-spacing: 0.2rem; */
  line-height: 1.2;
  /* min-height: 60vh; */
  /* max-height: 80vh; */
  max-width: 80%;
  text-align: justify;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

  /* position: fixed; */
}

.divider {
  height: 300px;
}

.title {
  text-align: center;
  margin-bottom: 4rem;
}

@media (max-width: 1200px) {
  .content {
    font-size: 1.2rem;
  }
}

@media (max-width: 800px) {
  .content {
    font-size: 1rem;
    padding: 2rem 2rem;
  }
}
@media (max-width: 600px) {
  .content {
    /* height: auto; */

    /* padding-top: 2rem; */
    /* padding-bottom: 2rem; */
    margin: 8rem 0px 4rem 0px;
    padding: 2rem 2rem;
  }

  .container {
    /* height: auto; */
    padding: 1rem 1rem;
  }
}
